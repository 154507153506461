import { gql } from 'apollo-angular';
export const Queries = {
  getUserAccountData: gql`
    query MyQuery($user_id: String!) {
      mrcog_library_content_user_mapping(
        where: { user_id: { _eq: $user_id } }
      ) {
        id
        user_id
        created_at
        user {
          name
        }
      }
    }
  `,
  listCourses: gql`
    query MyQuery {
      mrcog_topics(
        where: { parent_topic_id: { _is_null: true }, published: { _eq: true } }
        order_by: { created_at: asc }
      ) {
        name
        description
        id
      }
    }
  `,
  getDashboardContents: gql`
    query GetDashboardContents($id: uuid) {
      mrcog_topics(where: { id: { _eq: $id } }) {
        published
        showAudio: show_audio_library
        showDocument: show_document_library
        showEcourse: show_ecourse
        showEbook: show_ebook
        showFlipcard: show_flipcards_library
        showImage: show_image_library
        showMockTest: show_mock_test_library
        showQuiz: show_quiz_library
        showSummary: show_summary_library
        showVideo: show_video_library
        showCourse: show_course
        showCaseTemplate: show_case_templates
      }
    }
  `,

  listStreams: gql`
    query MyQuery($uid: uuid!) {
      mrcog_topics(
        where: { parent_topic_id: { _eq: $uid }, published: { _eq: true } }
        order_by: { created_at: asc }
      ) {
        name
        description
        id
      }
    }
  `,
  getUserData: gql`
    query MyQuery($uid: String!) {
      mrcog_users(where: { external_id: { _eq: $uid } }) {
        email
        name
        phone_number
      }
    }
  `,
  listProductLibrariesByType: gql`
    query MyQuery(
      $type: String
      $offset: Int
      $limit: Int
      $streamId: uuid!
      $userId: String
    ) {
      mrcog_library_course(
        limit: $limit
        offset: $offset
        where: {
          type: { _eq: $type }
          topic_id: { _eq: $streamId }
          is_active: { _eq: true }
        }
        order_by: { created_at: asc }
      ) {
        id
        name: title
        type
        topicId: topic_id
        libraryUserMappings: library_user_mappings(
          where: { user_id: { _eq: $userId } }
        ) {
          isEnrolled: is_enrolled
          subscriptionEndingAt: subscription_ending_at
        }
      }
      mrcog_library_course_aggregate(
        where: {
          type: { _eq: $type }
          topic_id: { _eq: $streamId }
          is_active: { _eq: true }
        }
        order_by: { created_at: asc }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  getECourse: gql`
    query MyQuery($id: uuid!, $userid: String!) {
      rating: mrcog_ecourse_main(
        where: {
          ecourse_main_user_mappings: { user_id: { _eq: $userid } }
          topic_id: { _eq: $id }
        }
      ) {
        ecourse_main_user_mappings(
          where: { user_id: { _eq: $userid } }
          limit: 1
          order_by: { subscription_ending_at: desc }
        ) {
          rating
          rating2
        }
      }
      mrcog_ecourse_main(
        where: { is_active: { _eq: true }, topic: { id: { _eq: $id } } }
        limit: 1
        order_by: { created_at: asc }
      ) {
        id
        name: title
        description
        featuredVideo: featured_video {
          id
          name: display_name
          url
        }
        ecourseMainUserMappings: ecourse_main_user_mappings(
          where: { user_id: { _eq: $userid } }
          limit: 1
          order_by: { subscription_ending_at: desc }
        ) {
          isEnrolled: is_enrolled
          subscriptionEndingAt: subscription_ending_at
        }
        ecourseCategories: ecourse_categories(
          where: { parent_id: { _is_null: true } }
          order_by: { created_at: asc }
        ) {
          id
          name
          childCategories: child_categories(order_by: { created_at: asc }) {
            id
            name
            ecourseContents: ecourse_contents(
              where: { is_active: { _eq: true } }
              order_by: { created_at: asc }
            ) {
              id
              name: title
              type
              isPaid: is_paid
              notes {
                id
              }
              questionsGroupId: questions_group_id
              mediaFile: media_file {
                id
                type
                url
                title: display_name
              }
              mediaSet: media_set {
                id
                name
                mediaMappings: media_set_media_mappings {
                  mediaFile: media_file {
                    id
                    name: display_name
                    type
                    thumbnailUrl: thumbnail_url
                    url
                  }
                }
              }
              ecourseContentUserMappings: ecourse_content_user_mappings(
                where: { user_id: { _eq: $userid } }
              ) {
                id
              }
              ecourseContentUserMappingsAggregate: ecourse_content_user_mappings_aggregate(
                where: { user_id: { _eq: $userid } }
              ) {
                aggregate {
                  count
                }
              }
            }
          }
        }

        aggregates: ecourse_main_user_mappings_aggregate(
          where: { is_enrolled: { _eq: true } }
        ) {
          aggregate {
            avg {
              rating2
            }
            count
            avg {
              rating
            }
            count
          }
        }
      }
    }
  `,
  listLibraryCategoriesById: gql`
    query MyQuery($id: uuid) {
      mrcog_library_categories(
        where: { library_id: { _eq: $id }, parent_id: { _is_null: true } }
        order_by: { created_at: asc }
      ) {
        id
        name
        isPaid: is_paid
        androidPrice: android_price
        iosPrice: ios_price
        webPrice: web_price
        parentId: parent_id
        libraryContents: library_contents(where: { is_active: { _eq: true } }) {
          id
        }
        childCategories: child_categories {
          id
        }
      }
    }
  `,
  getSubscriptionLibrarySubscription: gql`
    query MyQuery($id: uuid, $userId: String) {
      mrcog_library_user_mapping(
        where: { library_id: { _eq: $id }, user_id: { _eq: $userId } }
        order_by: { subscription_ending_at: desc }
        limit: 1
      ) {
        isEnrolled: is_enrolled
        subscriptionEndingAt: subscription_ending_at
      }
    }
  `,
  getLibraryContentById: gql`
    query MyQuery($id: uuid!, $userid: String!) {
      mrcog_library_categories(
        where: { id: { _eq: $id } }
        order_by: { created_at: asc }
      ) {
        isPaid: is_paid
        webPrice: web_price
        libraryContents: library_contents(
          where: { is_active: { _eq: true } }
          order_by: { created_at: asc }
        ) {
          id
          name: title
          type
          isPaid: is_paid
          isFeatured: is_featured
          content
          content2
          notes {
            id
          }
          questionsGroupId: questions_group_id
          mediaFile: media_file {
            name: display_name
            type
            id
            thumbnailUrl: thumbnail_url
            url
          }
          mediaFile2: media_file2 {
            id
            name: display_name
            type
            thumbnailUrl: thumbnail_url
            url
          }
          mediaSet: media_set {
            id
            name
            mediaMappings: media_set_media_mappings {
              mediaFile: media_file {
                id
                name: display_name
                type
                thumbnailUrl: thumbnail_url
                url
              }
            }
          }
          libraryContentUserMappings: library_content_user_mappings(
            where: { user_id: { _eq: $userid } }
          ) {
            id
            contentId: content_id
            typeId: library_id
            userId: user_id
          }
        }
        childCategories: child_categories(order_by: { created_at: asc }) {
          id
          name
          isPaid: is_paid
          androidPrice: android_price
          iosPrice: ios_price
          webPrice: web_price
          parentId: library_id
          libraryContents: library_contents(
            where: { is_active: { _eq: true } }
          ) {
            id
          }
          childCategories: child_categories {
            id
          }
        }
        libraryCategoryUserMappings: library_category_user_mappings(
          where: { user_id: { _eq: $userid } }
          order_by: { is_provisioned_at: desc }
          limit: 1
        ) {
          isEnrolled: is_provisioned
        }
      }
    }
  `,
  ecourseUsageByWeek: gql`
    query MyQuery(
      $firstDayOfWeek: timestamptz
      $lastDayOfWeek: timestamptz
      $userId: String
    ) {
      mrcog_ecourse_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          created_at: { _gte: $firstDayOfWeek, _lte: $lastDayOfWeek }
        }
      ) {
        nodes {
          created_at
        }
      }
      mrcog_library_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          created_at: { _gte: $firstDayOfWeek, _lte: $lastDayOfWeek }
        }
      ) {
        nodes {
          created_at
        }
      }
    }
  `,
  monthlyContentView: gql`
    query MyQuery(
      $userId: String
      $firstDayOfYear: timestamptz
      $lastDayOfYear: timestamptz
    ) {
      mrcog_ecourse_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          created_at: { _gte: $firstDayOfYear, _lte: $lastDayOfYear }
        }
      ) {
        aggregate {
          count
        }
        nodes {
          created_at
        }
      }
      mrcog_library_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          created_at: { _gte: $firstDayOfYear, _lte: $lastDayOfYear }
        }
      ) {
        aggregate {
          count
        }
        nodes {
          created_at
        }
      }
    }
  `,
  getUser: gql`
    query getUser($id: String) {
      mrcog_users(where: { external_id: { _eq: $id } }) {
        id
        name
        phone_number
        email
        is_active
        created_at
        prof_image
        batch_id
        location_lat
        location_long
        topic {
          name
          parent_topic {
            name
          }
        }
        chat_room_participants(order_by: { created_at: desc }) {
          created_at
          is_group_admin
          chat_room {
            name
            is_paid
          }
        }
        event_user_mappings {
          event {
            title
            event_date
          }
        }
        ecourse_main_user_mappings(order_by: { created_at: desc }) {
          created_at
          ecourse_id
          is_enrolled
          enrolled_at
          subscribed_at
          subscription_ending_at
          ecourse_main_plan_mapping {
            plan {
              name
            }
          }
          ecourse_main {
            featured_image {
              url
              type
            }
            ecourse_contents_aggregate {
              aggregate {
                count
              }
            }
            ecourse_contents_aggregate_attended: ecourse_contents_aggregate(
              where: {
                ecourse_content_user_mappings: { user_id: { _eq: $id } }
              }
            ) {
              aggregate {
                count
              }
            }
            title
          }
        }
        library_user_mappings(order_by: { created_at: desc }) {
          created_at
          library_id
          is_enrolled
          enrolled_at
          subscribed_at
          subscription_ending_at
          library_plan_mapping {
            plan {
              name
            }
          }
          library_course {
            is_active
            type
            featured_image {
              url
              type
            }
            library_contents_aggregate {
              aggregate {
                count
              }
            }
            library_contents_aggregate_attended: library_contents_aggregate(
              where: {
                library_content_user_mappings: { user_id: { _eq: $id } }
              }
            ) {
              aggregate {
                count
              }
            }
            title
          }
        }
        ecourse_main_user_mappings_aggregate(
          where: { subscription_ending_at: { _gt: "NOW()" } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `,
  getLibraryProgress: gql`
    query MyQuery($libraryId: uuid!, $libId: String, $userId: String) {
      mrcog_library_contents_aggregate(
        where: { library_id: { _eq: $libraryId } }
      ) {
        aggregate {
          count
        }
      }
      mrcog_library_content_user_mapping_aggregate(
        where: { user_id: { _eq: $userId }, library_id: { _eq: $libId } }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  getTrendingVideos: gql`
    query MyQuery {
      mrcog_library_contents(
        where: {
          media_file: { type: { _eq: video } }
          library_course: {
            topic_id: { _eq: "145cb0be-649f-4eb4-bb32-01ef12de35e0" }
          }
          is_featured: { _eq: true }
        }
        limit: 5
      ) {
        title
        mediaFile: media_file {
          url
          type
          thumbnailUrl: thumbnail_url
          title: display_name
        }
      }
    }
  `,
  listECourseCategories: gql`
    query MyQuery($eid: uuid) {
      mrcog_ecourse_categories(
        where: { ecourse_id: { _eq: $eid }, parent_id: { _is_null: true } }
      ) {
        id
        name
        parent_id
        childCategories: child_categories {
          id
        }
        eCourseContents: ecourse_contents {
          id
        }
      }
    }
  `,
  listECourseCategoriesById: gql`
    query MyQuery($cid: uuid) {
      mrcog_ecourse_categories(
        where: { parent_id: { _is_null: true }, id: { _eq: $cid } }
      ) {
        id
        name
        parent_id
        childCategories: child_categories {
          id
          name
          parent_id
          childCategories: child_categories {
            id
          }
        }
        eCourseContents: ecourse_contents {
          id
          title
          type
          isPaid: is_paid
          isActive: is_active
          mediaFile: media_file {
            name: display_name
            type
            id
            thumbnailUrl: thumbnail_url
            url
          }
        }
      }
    }
  `,
  getMediaContentById: gql`
    query MyQuery($id: uuid) {
      mrcog_media_files(where: { id: { _eq: $id } }) {
        display_name
        url
        type
      }
    }
  `,
  getUserDetails: gql`
    query MyQuery($uid: String!) {
      mrcog_users(where: { external_id: { _eq: $uid } }) {
        email
        name
        phoneNumber: phone_number
        selectedTopicId: selected_topic_id
        imageUrl: prof_image
        topic {
          name
          parent_topic {
            name
          }
        }
      }
    }
  `,
  getLibraryViewCounts: gql`
    query getLibraryViewCounts($userId: String) {
      count_video: mrcog_library_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          library_content: { type: { _eq: "video" } }
        }
      ) {
        aggregate {
          count
        }
      }
      count_audio: mrcog_library_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          library_content: { type: { _eq: "audio" } }
        }
      ) {
        aggregate {
          count
        }
      }
      count_quiz: mrcog_library_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          library_content: { type: { _eq: "quiz" } }
        }
      ) {
        aggregate {
          count
        }
      }
      count_mock_test: mrcog_library_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          library_content: { type: { _eq: "mock_test" } }
        }
      ) {
        aggregate {
          count
        }
      }
      count_pdf: mrcog_library_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          library_content: { type: { _eq: "pdf" } }
        }
      ) {
        aggregate {
          count
        }
      }
      count_text: mrcog_library_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          library_content: { type: { _eq: "text" } }
        }
      ) {
        aggregate {
          count
        }
      }
      count_image_set: mrcog_library_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          library_content: { type: { _eq: "image_set" } }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  getEcourseViewCounts: gql`
    query EcourseViewCounts($userId: String) {
      count_video: mrcog_ecourse_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          ecourse_content: { type: { _eq: video } }
        }
      ) {
        aggregate {
          count
        }
      }
      count_audio: mrcog_ecourse_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          ecourse_content: { type: { _eq: audio } }
        }
      ) {
        aggregate {
          count
        }
      }
      count_quiz: mrcog_ecourse_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          ecourse_content: { type: { _eq: quiz } }
        }
      ) {
        aggregate {
          count
        }
      }
      count_mock_test: mrcog_ecourse_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          ecourse_content: { type: { _eq: mock_test } }
        }
      ) {
        aggregate {
          count
        }
      }
      count_pdf: mrcog_ecourse_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          ecourse_content: { type: { _eq: pdf } }
        }
      ) {
        aggregate {
          count
        }
      }
      count_text: mrcog_ecourse_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          ecourse_content: { type: { _eq: text } }
        }
      ) {
        aggregate {
          count
        }
      }
      count_image_set: mrcog_ecourse_content_user_mapping_aggregate(
        where: {
          user_id: { _eq: $userId }
          ecourse_content: { type: { _eq: image_set } }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  getWebConfigurations: gql`
    query Config {
      mrcog_configurations(where: { platform: { _eq: "web" } }) {
        key
        value
      }
    }
  `,
  getImageSetData: gql`
    query getImageSetData($id: uuid) {
      mrcog_media_set_media_mapping(where: { media_set_id: { _eq: $id } }) {
        mediaFile: media_file {
          title: display_name
          url
          type
        }
      }
    }
  `,
  getLibraryContent: gql`
    query getSummaryContent($id: uuid) {
      mrcog_library_contents(where: { id: { _eq: $id } }) {
        name: title
        type
        content
        media_file {
          display_name
          url
        }
      }
    }
  `,
  checkPurchase: gql`
    query checkPurchase($userId: String, $contentId: uuid) {
      mrcog_library_content_user_mapping(
        where: { user_id: { _eq: $userId }, content_id: { _eq: $contentId } }
      ) {
        id
      }
    }
  `,
  CheckEcoursePurchase: gql`
    query CheckEcoursePurchase($userId: String, $contentId: uuid) {
      mrcog_ecourse_content_user_mapping(
        where: { user_id: { _eq: $userId }, content_id: { _eq: $contentId } }
      ) {
        id
      }
    }
  `,
  getLuckyDraw: gql`
    query getLuckyDraw {
      draw: mrcog_lucky_draw {
        course_id
        ecourse_id
        library_id
        library_course {
          title
        }
        ecourse_main {
          title
        }
        course {
          title
        }
      }
    }
  `,
  getLuckyDrawDetails: gql`
    query LuckyDraw($user_id: String) {
      user: mrcog_lucky_draw_user_mapping(
        where: { user_id: { _eq: $user_id } }
      ) {
        id
      }
    }
  `,
  getEcourseContent: gql`
    query getEcourseSummaryContent($id: uuid) {
      mrcog_ecourse_contents(where: { id: { _eq: $id } }) {
        name: title
        type
        content
        media_file {
          display_name
          url
        }
      }
    }
  `,
  getQuizContent: gql`
    query getQuizContent($id: uuid) {
      mrcog_questions_group(where: { id: { _eq: $id } }) {
        batchId: batch_id
        isActive: is_active
        name
        type
        passMark: pass_mark
        totalDuration: total_duration
        totalMark: total_mark
        updatedAt: updated_at
        isEmq: is_emq
        questionsMain: questions_mains(
          where: { is_active: { _eq: true } }
          order_by: { created_at: asc }
        ) {
          content
          id
          explanation
          source
          heading
          questionsOptions: questions_options(order_by: { created_at: asc }) {
            id
            isAnswer: is_answer
            optionName: option_name
            QuestionMainId: question_main_id
          }
          questionsExtended: questions_extendeds(
            order_by: { created_at: asc }
          ) {
            explanation
            content
            answer_id
            id
            title
          }
        }
      }
    }
  `,
  getFlipCardContent: gql`
    query MyQuery($id: uuid) {
      mrcog_library_contents(where: { id: { _eq: $id } }) {
        mediaFile: media_file {
          display_name
          url
          type
          thumbnail_url
          __typename
        }
        content
        mediaFile2: media_file2 {
          display_name
          url
          type
          thumbnail_url
          __typename
        }
        content2
      }
    }
  `,

  listECourseSubscription: gql`
    query MyQuery($id: uuid!) {
      mrcog_ecourse_main_plan_mapping(
        where: { ecourse_id: { _eq: $id }, is_active: { _eq: true } }
        order_by: { created_at: asc }
      ) {
        id
        webPrice: web_price
        ecourse: ecourse_main {
          id
          description
          name: title
        }
        plan {
          id
          description
          name
          validity
        }
      }
    }
  `,
  getLibraryNotes: gql`
    query MyQuery($userId: String, $libraryId: uuid) {
      mrcog_library_contents(where: { id: { _eq: $libraryId } }) {
        notes(where: { user_id: { _eq: $userId } }) {
          id
          content
        }
      }
    }
  `,
  getEbookNotes: gql`
    query ebooknote($ebookId: uuid, $userId: String) {
      mrcog_notes(
        where: {
          ebook_content_id: { _eq: $ebookId }
          user_id: { _eq: $userId }
        }
      ) {
        id
        content
      }
    }
  `,
  getEcourseNotes: gql`
    query MyQuery($userId: String, $libraryId: uuid) {
      mrcog_ecourse_contents(where: { id: { _eq: $libraryId } }) {
        notes(where: { user_id: { _eq: $userId } }) {
          id
          content
        }
      }
    }
  `,
  getCourse: gql`
    query MyQuery($id: uuid!, $userId: String!) {
      mrcog_course(
        where: { id: { _eq: $id }, is_active: { _eq: true } }
        order_by: { updated_at: desc }
        limit: 1
      ) {
        id
        description
        name: title
        libraryId: library_id
        libraryFeatures: library_features
        featuredVideo: mediaFileByFeaturedVideoId {
          id
          title: display_name
          thumbnailUrl: thumbnail_url
          url
        }
        featuredImage: media_file {
          id
          title: display_name
          thumbnailUrl: thumbnail_url
          url
        }
        courseFeatures: course_features {
          id
          description
          content: media_file {
            id
            title: display_name
            thumbnailUrl: thumbnail_url
            url
          }
        }
        courseMainUserMappings: course_user_mappings(
          where: { user_id: { _eq: $userId } }
          order_by: { updated_at: desc }
          limit: 1
        ) {
          id
          isPaid: is_purchased
          purchaseDate: purchased_at
        }
        courseUserMappingsAggregate: course_user_mappings_aggregate(
          where: { user_id: { _eq: $userId } }
        ) {
          aggregate {
            count
            avg {
              rating
            }
          }
        }
      }
    }
  `,
  getCategoryPrice: gql`
    query MyQuery($id: uuid!) {
      mrcog_library_categories(where: { id: { _eq: $id } }) {
        name
        webPrice: web_price
        topic: library_course {
          id
          name: title
          description: type
        }
      }
    }
  `,

  listLibrarySubscription: gql`
    query MyQuery($id: uuid!, $type: String) {
      mrcog_library_plan_mapping(
        where: {
          library_id: { _eq: $id }
          is_active: { _eq: true }
          library_course: { type: { _eq: $type } }
        }
        order_by: { created_at: asc }
      ) {
        id
        webPrice: web_price
        ecourse: library_course {
          id
          description
          name: title
        }
        plan {
          id
          description
          name
          validity
        }
      }
    }
  `,

  getECourseRating: gql`
    query MyQuery($ecourseId: uuid, $userId: String) {
      mrcog_ecourse_main_user_mapping(
        where: {
          user_id: { _eq: $userId }
          ecourse_main: {
            topic_id: { _eq: $ecourseId }
            is_active: { _eq: true }
          }
        }
      ) {
        rating
        rating2
      }
    }
  `,

  listCourseSubscription: gql`
    query MyQuery($id: uuid!) {
      mrcog_course_plan_mapping(
        where: { course_id: { _eq: $id }, is_active: { _eq: true } }
        order_by: { created_at: asc }
      ) {
        id
        webPrice: web_price
        ecourse: course {
          id
          description
          name: title
        }
        plan {
          id
          description
          name
          validity
        }
      }
    }
  `,

  getQuestionFeedback: gql`
    query MyQuery($questionId: uuid!, $userId: String!, $type: String!) {
      mrcog_questions_feedbacks(
        where: {
          question_id: { _eq: $questionId }
          user_id: { _eq: $userId }
          type: { _eq: $type }
        }
      ) {
        id
        feedback
        feedback_time
        is_replied
        reply
        reply_time
      }
    }
  `,

  getECourseUserRating: gql`
    query MyQuery($ecourseId: uuid!, $userId: String!) {
      mrcog_ecourse_main_user_mapping(
        where: { ecourse_id: { _eq: $ecourseId }, user_id: { _eq: $userId } }
      ) {
        id
        rating
        rating2
      }
    }
  `,

  getCourseLibraryUserMapping: gql`
    query MyQuery($userId: String!, $libraryId: uuid!) {
      mrcog_library_course(
        where: {
          type: { _eq: "course" }
          is_active: { _eq: true }
          id: { _eq: $libraryId }
        }
      ) {
        id
        description
        name: title
        courses {
          id
          description
          name: title
          courseMainUserMappings: course_user_mappings(
            where: { user_id: { _eq: $userId } }
          ) {
            isPaid: is_purchased
            purchaseDate: purchased_at
          }
        }
      }
    }
  `,

  listAllCoursesByTopic: gql`
    query MyQuery($streamId: uuid!) {
      mrcog_course(
        where: { is_active: { _eq: true }, topic_id: { _eq: $streamId } }
        order_by: { created_at: asc }
      ) {
        id
        name: title
      }
    }
  `,

  // ebookPrice

  getEbookPrice: gql`
    query getEbookPrice($ebookId: uuid!) {
      mrcog_ebooks(where: { id: { _eq: $ebookId } }) {
        name: name
        webPrice: web_price
        topic: topicByTopic {
          id
          name
          description
        }
      }
    }
  `,

  getCoursePrice: gql`
    query MyQuery($courseId: uuid!) {
      mrcog_course(where: { id: { _eq: $courseId } }) {
        name: title
        webPrice: web_price
        topic {
          id
          name
          description
        }
      }
    }
  `,
  GetFlaggedQuestions: gql`
    query GetFlaggedQuestions($userId: String) {
      mrcog_questions_feedbacks(
        where: { type: { _eq: "flag" }, user_id: { _eq: $userId } }
      ) {
        questions_main {
          heading
        }
        is_replied
        reply
        reply_time
      }
    }
  `,
  GetPurchases: gql`
    query GetPurchases($userId: String!) {
      mrcog_library_category_user_mapping(
        where: { user_id: { _eq: $userId }, is_provisioned: { _eq: true } }
      ) {
        id
        library_category {
          name
          library_course {
            title
            type
            id
            topic {
              id
              name
            }
          }
        }
      }
    }
  `,
  GetPurchasedCourses: gql`
    query GetPurchasedCourses($userId: String) {
      mrcog_course_user_mapping(
        where: { user_id: { _eq: $userId }, is_purchased: { _eq: true } }
      ) {
        course {
          id
          title
          featured_image_id
          topic {
            id
            name
          }
        }
      }
    }
  `,
  GetEbook: gql`
    query Ebook($topic: uuid) {
      mrcog_ebooks(
        where: { topic: { _eq: $topic }, is_active: { _eq: true } }
      ) {
        name
        id
        media_id
        is_paid
        page_limit: page_restriction_count
        media_file {
          display_name
          url
          type
        }
      }
    }
  `,
  getEbookUserMappings: gql`
    query getEbookUserMappings(
      $where: mrcog_ebooks_user_mapping_bool_exp! = {}
    ) {
      mrcog_ebooks_user_mapping(where: $where) {
        id
        is_purchased
      }
    }
  `,
  listPaymentGateways: gql`
    query MyQuery {
      mrcog_payment_gateways {
        id
        name
        key
        isActive: is_active
        logo
      }
    }
  `,

  getNotifications: gql`
    query MyQuery($user_id: String) {
      mrcog_notifications(
        where: { is_read: { _eq: false }, user_id: { _eq: $user_id } }
      ) {
        data
        message
        title
        user {
          name
          prof_image
        }
      }
    }
  `,

  getChatRooomId: gql`
    query getChatRoomId($course_id: uuid) {
      mrcog_course(where: { id: { _eq: $course_id } }) {
        chat_room_id
      }
    }
  `,

  getDeviceToken: gql`
    query getDeviceToken($user_id: String!) {
      mrcog_users(where: { external_id: { _eq: $user_id } }) {
        device_token
      }
    }
  `,

  checkUserEmailExist: gql`
    query checkUserEmailExist($email: String!) {
      mrcog_users(where: { email: { _eq: $email } }) {
        email
        name
        phone_number
      }
    }
  `,
};
