import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { DataService } from './data.service';
import { catchError, map } from 'rxjs/operators';
import { PaymentRequest } from '../data-model/payment-request';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { StripeIntent } from '../data-model/stripe-intent';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private profilePicSubject = new Subject<string>();

  profilePic$ = this.profilePicSubject.asObservable();
  // private profilePicSubject = new BehaviorSubject<string>('');
  // profilePic$: Observable<string> = this.profilePicSubject.asObservable();
  public configurations: any;
  baseUrl = environment.cloudfront.url;
  basePaymentUrl = environment.nodeRootURL.url;
  constructor(
    public afAuth: AngularFireAuth,
    public dataService: DataService,
    private http: HttpClient
  ) {
    this.fetchConfig();
    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        this.fetchConfig();
      }
    });
  }
  fetchConfig(): void {
    this.dataService
      .getWebConfigurations()
      .pipe(map((res: any) => res.data['mrcog_configurations']))
      .subscribe((data) => {
        const tempConfig: any = {};
        data.forEach((item: any) => (tempConfig[item.key] = item.value));
        this.configurations = Object.assign({}, tempConfig);
      });
  }
  public getMediaSrc(row: any): string {
    let url = '';
    switch (row.type) {
      case 'video':
        url = this.baseUrl + row.thumbnail_url;
        break;
      case 'audio':
        url = 'assets/images/temp_img.png';
        break;
      case 'document':
        url = 'assets/images/temp_img.png';
        break;
      case 'image':
        url =
          row.url && this.configurations?.aws_web_image_bucket_folder
            ? this.baseUrl +
              '/' +
              this.configurations.aws_web_image_bucket_folder +
              row.url
            : 'assets/images/temp_img.png';
        break;
      default:
        url = 'assets/images/temp_img.png';
    }
    return url;
  }

  getSignedURL(key: string, type: string) {
    const url = environment.nodeRootURL.url + 's3/get-signedURL';
    let bucket,
      Key: null | string = null;
    switch (type) {
      case 'audio':
        bucket = this.configurations?.aws_web_audio_bucket_folder;
        Key = this.configurations.aws_web_audio_bucket_folder + key;
        break;
      case 'pdf':
        bucket = this.configurations?.aws_web_media_bucket;
        Key = this.configurations?.aws_web_document_bucket_folder + key;
        break;
      case 'video':
        bucket = this.configurations?.aws_web_media_bucket;
        break;
      case 'case':
        bucket = this.configurations?.aws_web_media_bucket;
        Key = this.configurations.aws_web_document_bucket_folder + key;
        break;
      case 'epub':
        bucket = this.configurations?.aws_web_media_bucket;
        Key = this.configurations.aws_web_ebook_bucket_folder + key;
        break;
      case 'img':
        bucket = environment.bucket;
        break;
      default:
        bucket = '';
        break;
    }
    if (!Key) Key = key;
    return this.http.post(
      url,
      {
        bucket,
        Key,
      },
      { responseType: 'text' }
    );
  }

  getObject(key: string, type: string) {
    const url = environment.nodeRootURL.url + 's3/get-object';

    let bucket,
      Key: null | string = null;
    switch (type) {
      case 'audio':
        bucket = this.configurations.aws_web_audio_bucket_folder;
        Key = this.configurations.aws_web_audio_bucket_folder + key;
        break;
      case 'pdf':
        bucket = this.configurations.aws_web_media_bucket;
        Key = this.configurations.aws_web_document_bucket_folder + key;
        break;
      case 'video':
        bucket = this.configurations.aws_web_media_bucket;
        break;
      case 'case':
        bucket = this.configurations.aws_web_media_bucket;
        Key = this.configurations.aws_web_document_bucket_folder + key;
        break;
      case 'epub':
        bucket = this.configurations.aws_web_media_bucket;
        Key = this.configurations.aws_web_ebook_bucket_folder + key;
        break;
      default:
        bucket = '';
        break;
    }
    if (!Key) Key = key;

    return this.http.post(
      url,
      {
        bucket,
        Key,
      },
      { responseType: 'text' }
    );
  }

  getSrc(media: string, path: string, cb: any) {
    let url = '';
    switch (media) {
      case 'audio':
        cb(
          this.baseUrl +
            '/' +
            this.configurations.aws_web_audio_bucket_folder +
            path
        );
        break;
      case 'pdf':
        cb(
          `https://${this.configurations.aws_web_media_bucket}.s3.amazonaws.com` +
            '/' +
            this.configurations.aws_web_document_bucket_folder +
            path
        );
        break;
      case 'video':
        cb(
          `https://${this.configurations.aws_web_media_bucket}.s3.amazonaws.com` +
            '/' +
            path
        );
        break;
      case 'case':
        cb(
          `https://${this.configurations.aws_web_media_bucket}.s3.amazonaws.com` +
            '/' +
            this.configurations.aws_web_document_bucket_folder +
            path
        );
        break;
      case 'epub':
        cb(
          `https://${this.configurations.aws_web_media_bucket}.s3.amazonaws.com` +
            '/' +
            this.configurations.aws_web_ebook_bucket_folder +
            path
        );
        break;
      default:
        url = 'default';
        break;
    }
  }
  getIconSrc(type: string) {
    let url = '';
    switch (type) {
      case 'video':
        url = '/assets/images/icons/Video.png';
        break;
      case 'audio':
        url = '/assets/images/icons/Audio.png';
        break;
      case 'pdf':
        url = '/assets/images/icons/Summary.png';
        break;
      case 'text':
        url = '/assets/images/icons/SyllabusCompilation.png';
        break;
      case 'image':
        url = '/assets/images/icons/Image.png';
        break;
      case 'flip_card':
        url = '/assets/images/icons/FlashCards.png';
        break;
      case 'quiz':
        url = '/assets/images/icons/Quiz.png';
        break;
      case 'mock_test':
        url = '/assets/images/icons/MockTest.png';
        break;
      case 'free':
        url = '/assets/images/icons/Freelibrary.png';
        break;
      case 'subscription':
        url = '/assets/images/icons/SubscriptionLibrary.png';
        break;
      case 'case_template':
        url = '/assets/images/icons/CaseTemplate.png';
        break;
      default:
        url = '/assets/images/icons/FeaturedContent.png';
    }
    return url;
  }

  public getStripePaymentSession(paymentrequest: PaymentRequest): any {
    let url = this.basePaymentUrl + 'order/session';
    return this.http
      .post(url, {
        success_url: paymentrequest.success_url,
        cancel_url: paymentrequest.cancel_url,
        name: paymentrequest.name,
        type: paymentrequest.type,
        subscription_id: paymentrequest.subscriptionId,
        category_id: paymentrequest.categoryId,
        course_id: paymentrequest.course_id,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public sendDetails(data: any) {
    let url = this.basePaymentUrl + 'send-details';
    return this.http.post(url, data).pipe(catchError(this.errorHandler));
  }
  public sendToUser(data: any) {
    let url = this.basePaymentUrl + 'email';
    return this.http.post(url, data).pipe(catchError(this.errorHandler));
  }
  public paymentProvision(data: any) {
    let url = this.basePaymentUrl + 'order/provision';
    this.http
      .post(url, data, { withCredentials: true })
      .pipe(catchError(this.errorHandler));
  }

  public verifyStripeOrder(session_id: string): any {
    let url = this.basePaymentUrl + 'order/verifySession';
    return this.http
      .post(url, {
        session_id,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public getRazorPayPaymentSession(paymentrequest: PaymentRequest): any {
    let url = this.basePaymentUrl + 'order/createOrder';
    return this.http
      .post(url, {
        category_id: paymentrequest.categoryId,
        subscription_id: paymentrequest.subscriptionId,
        course_id: paymentrequest.course_id,
        ebook_id: paymentrequest.ebook_id,
        type: paymentrequest.type,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  public unlockItems(data: any): any {
    let url = this.basePaymentUrl + 'order/unlock';
    return this.http.post(url, data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public verifyRazorPayOrder(data: any, type: string): any {
    let url = this.basePaymentUrl + 'order/verifyOrder';
    return this.http
      .post(url, {
        payment_id: data.razorpay_payment_id,
        order_id: data.razorpay_order_id,
        razorpay_signature: data.razorpay_signature,
        type: type,
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
  getPDF(url: string) {
    // const header = new Headers({ skip: "true" });
    // const httpOptions: Object = {
    //   headers: headers,
    //   responseType: 'text' as 'json'
    // };
    return this.http.get<Blob>(url, {
      headers: new HttpHeaders({ skip: 'true' }),
    });
  }

  // send user and chat id
  sendChatRoomData(chatRoomId: String, userId: string) {
    const url = environment.nodeRootURL.url + 'fcm/add-user';
    return this.http.post(url, { chatRoomId, userId });
  }

  updateProfilePic(url: string) {
    this.profilePicSubject.next(url);
  }
}
