import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { interval, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { DataService } from './data.service';
import { User } from '../data-model/User';
import { CookieService } from 'ngx-cookie-service';
import {
  AuthError,
  ConfirmationResult,
  RecaptchaVerifier,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  isLoggedIn = false;
  error: string = '';
  private subscriptions!: Subscription;
  private tokenExpiryHandleSubscription: Subscription;
  uid: string = '';
  verificationCode: string = '';
  user: any;
  userData!: User;
  private querySubscription: Subscription = new Subscription();

  constructor(
    public firebaseAuth: AngularFireAuth,
    private router: Router,
    private cookieService: CookieService,
    private dataService: DataService,
    private afAuth: AngularFireAuth
  ) {
    this.firebaseAuth.onAuthStateChanged((user: any) => {
      // console.log(this.router.url);
      if (user) {
        this.subscriptions = firebaseAuth.idTokenResult.subscribe((data) => {
          if (data != null) {
            this.isLoggedIn = true;
            this.setUserData(user, data.token);
          }
        });
      } else {
        localStorage.removeItem('user');
        this.isLoggedIn = false;
        if (this.router.url != '/home') {
          this.router.navigate(['/login']);
        }
        if (this.subscriptions) {
          this.subscriptions.unsubscribe();
        }
      }
    });
    const source = interval(1000 * 60 * 15);
    this.tokenExpiryHandleSubscription = source.subscribe(() =>
      this.refreshToken()
    );
  }

  private setUserData(user: any, token: any) {
    // console.log(user.displayName);
    // console.log(user.photoURL);
    const userInfo = {
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      photoURL: user.photoURL,
      uid: user.uid,
    };
    this.user = userInfo;
    localStorage.setItem('user', JSON.stringify(userInfo));
    localStorage.setItem('token', token);
  }
  signInWithPhone(res: any, returnUrl: any, cb: any) {
    const authType = 'phone';
    //this.isLoggedIn = true;
    //console.log('User Logged In');
    this.querySubscription = this.dataService
      .getUserDetails(this.user.uid)
      .subscribe(({ data }) => {
        localStorage.setItem('userData', JSON.stringify(data.mrcog_users[0]));
        this.userData = data.mrcog_users[0];
        if (
          this.user.displayName != null &&
          this.user.photoURL != null &&
          this.user.email != null
        ) {
          if (this.userData) {
            if (this.userData.selectedTopicId != null) {
              if (this.cookieService.check('selectedStream')) {
                this.cookieService.delete('selectedStream');
              }
              if (this.cookieService.check('selectedStreamName')) {
                this.cookieService.delete('selectedStreamName');
              }
              this.cookieService.set(
                'selectedStream',
                this.userData.selectedTopicId
              );
              this.cookieService.set(
                'selectedStreamName',
                this.userData.topic.name
              );
              if (returnUrl != '/all-courses') {
                this.router.navigateByUrl(returnUrl);
              } else {
                this.router.navigate(['/dashboard']);
              }
              cb();
            } else {
              this.router.navigateByUrl(returnUrl);
              cb();
            }
          }
        } else {
          if (returnUrl != '/all-courses') {
            this.router.navigate(['register'], {
              queryParams: { returnUrl: returnUrl },
            });
          } else {
            this.router.navigateByUrl(`/register/${authType}`);
          }

          cb();
        }
      });
  }

  userLogin(num: string, reCaptchaVerifier: any, call: any) {
    firebase
      .auth()
      .signInWithPhoneNumber(num, reCaptchaVerifier)
      .then((confirmationResult) => {
        // console.log('send', confirmationResult);
        call(1, confirmationResult);
      })
      .catch((error) => {
        console.log(error.message);
        alert(error.message);
        call(2, error);
      });
  }
  userConfirmLogin(credential: any, returnUrl: any, cb: any) {

    firebase
      .auth()
      .signInWithCredential(credential)
      .then((response) => {
        // console.log(response);
        this.refreshToken();
        //claims key error delay time
        setTimeout(() => {
          this.refreshToken();
        }, 2000);
        setTimeout(() => {
          this.refreshToken();
        }, 6000);

        // this.signInWithPhone(response, returnUrl, () => {
        //   console.log('cb working here at 132');
        //   cb(1, response);
        // });

        setTimeout(() => {
          this.signInWithPhone(response, returnUrl, () => {
            // console.log('cb working here at 132');
            cb(1, response);
          });
        }, 7000);
      })
      .catch((error) => {
        console.log(error);
        cb(2, error);
        this.error = error;
      });
  }

  updateUserData(name: string, url: string, email: string, cb: any) {
    const profile = {
      displayName: name,
      photoURL: url,
    };
    firebase
      .auth()
      .currentUser?.updateProfile({
        displayName: name,
        photoURL: url,
      })
      .then(
        function () {
          // Profile updated successfully!
          firebase
            .auth()
            .currentUser?.updateEmail(email)
            .then(
              () => {
                // console.log('Profile updated successfully!');
                cb();
              },
              (error) => {
                console.log('An error happened.', error);
                cb();
              }
            );
        },
        function (error) {
          // An error happened.
          console.log('An error happened.', error);
          cb();
        }
      );
  }

  logout() {
    this.firebaseAuth.signOut();
    this.isLoggedIn = false;
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    let data = localStorage.getItem('user');
    if (data) {
      localStorage.clear();
      location.reload();
    }
  }

  loggedCheck() {
    if (
      localStorage.getItem('token') != null &&
      localStorage.getItem('user') != null
    ) {
      return true;
    } else {
      return false;
    }
  }
  // loggedCheck() {
  //   if (this.isLoggedIn) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  isUserLoggedIn(): boolean {
    if (this.loggedCheck()) {
      return true;
    }
    return false;
  }

  // to check if user has null email and name -->
  // if yes --> redirect to /register
  // using authguard

  isUserRegistered(): boolean {
    if (!localStorage.getItem('userData')) {
      return true;
    }

    let user = JSON.parse(localStorage.getItem('userData')!);
    // console.log('USER LOG', this.user);

    if (
      user.email == null ||
      user.email == '' ||
      user.name == null ||
      user.name == ''
    )
      return false;
    return true;
  }

  public refreshToken(): void {
    this.firebaseAuth.currentUser.then((user) => {
      if (user) {
        // console.log('refreshToken');
        user.getIdToken(true).then((token: any) => {
          if (token) {
            // console.log('token refreshed');
            localStorage.setItem('token', token);
          }
        });
      }
    });
  }

  public get currentUserToken(): any {
    return localStorage.getItem('token');
  }

  loginWithEmail(email: string, password: string, returnUrl: any, cb: any) {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        if (user.emailVerified) {
          localStorage.setItem('token', (user as any).accessToken);
          localStorage.setItem('userData', JSON.stringify({ data: 'student' }));
          await this.refreshTokenWithDelay(2000);
          await this.refreshTokenWithDelay(7000);

          this.querySubscription = this.dataService
            .getUserDetails(this.user.uid)
            .subscribe(async ({ data }) => {
              localStorage.setItem(
                'userData',
                JSON.stringify({ data: 'student' })
              );
              this.userData = data.mrcog_users[0];
              localStorage.setItem('userData', JSON.stringify(this.userData));
              await this.waitForUserData();
              if (
                this.user.displayName &&
                this.user.photoURL &&
                this.user.email &&
                this.userData &&
                this.userData.selectedTopicId
              ) {
                if (this.cookieService.check('selectedStream')) {
                  this.cookieService.delete('selectedStream');
                }
                if (this.cookieService.check('selectedStreamName')) {
                  this.cookieService.delete('selectedStreamName');
                }
                this.cookieService.set(
                  'selectedStream',
                  this.userData.selectedTopicId
                );
                this.cookieService.set(
                  'selectedStreamName',
                  this.userData.topic.name
                );
                const userData = localStorage.getItem('userData');
                if (userData) {
                  this.handleReturnUrl(returnUrl);
                }
              } else {
                const userData = localStorage.getItem('userData');
                if (userData) {
                  this.handleReturnUrl(returnUrl);
                }
              }
            });
        } else {
          await sendEmailVerification(user);
          throw new Error(
            'Please verify your email before logging in. Check your email for the verification link.'
          );
        }
        return userCredential;
      })
      .catch((error) => {
        console.log(error);
        // Display alert message here if needed
        alert(error.message);
        throw error;
      })
      .finally(() => {
        cb();
      });
  }

  // loginWithEmail(email: string, password: string, returnUrl: any, cb: any) {
  //   const auth = getAuth();
  //   return signInWithEmailAndPassword(auth, email, password)
  //     .then(async (userCredential) => {
  //       const user = userCredential.user;
  //       console.log(user.emailVerified);
  //       localStorage.setItem('token', (user as any).accessToken);
  //       localStorage.setItem('userData', JSON.stringify({data: "student"}));
  //       await this.refreshTokenWithDelay(2000);
  //       await this.refreshTokenWithDelay(6000);

  //       this.querySubscription = this.dataService
  //         .getUserDetails(this.user.uid)
  //         .subscribe(async ({ data }) => {
  //           localStorage.setItem('userData', JSON.stringify({data: "student"}));
  //           this.userData = data.mrcog_users[0];
  //           console.log("User data", JSON.stringify(this.userData));
  //           localStorage.setItem('userData', JSON.stringify(this.userData));
  //           // await new Promise((resolve) => setTimeout(resolve, 1000));
  //           localStorage.setItem('userData', JSON.stringify(this.userData));
  //           await this.waitForUserData();
  //           console.log("User data", JSON.stringify(this.userData));
  //           if (
  //             this.user.displayName &&
  //             this.user.photoURL &&
  //             this.user.email &&
  //             this.userData &&
  //             this.userData.selectedTopicId
  //           ) {
  //             // await new Promise((resolve) => setTimeout(resolve, 1000));
  //             if (this.cookieService.check('selectedStream')) {
  //               this.cookieService.delete('selectedStream');
  //             }
  //             if (this.cookieService.check('selectedStreamName')) {
  //               this.cookieService.delete('selectedStreamName');
  //             }
  //             this.cookieService.set('selectedStream', this.userData.selectedTopicId);
  //             this.cookieService.set('selectedStreamName', this.userData.topic.name);
  //             const userData = localStorage.getItem('userData');
  //             if (userData) {
  //               this.handleReturnUrl(returnUrl);
  //             }
  //           } else {
  //             const userData = localStorage.getItem('userData');
  //             if (userData) {
  //             this.handleReturnUrl(returnUrl);
  //             }
  //           }
  //         });

  //       return userCredential;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       throw error;
  //     })
  //     .finally(() => {
  //       cb();
  //     });
  // }

  waitForUserData() {
    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        const userData = localStorage.getItem('userData');
        if (userData) {
          clearInterval(interval);
          resolve();
        }
      }, 100); // Check every 100 milliseconds
    });
  }

  async refreshTokenWithDelay(delay: number) {
    await new Promise((resolve) => setTimeout(resolve, delay));
    this.refreshToken();
  }

  handleReturnUrl(returnUrl: string) {
    if (returnUrl !== '/all-courses') {
      this.router.navigateByUrl(returnUrl);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  async PasswordReset(email: string): Promise<void> {
    try {
      const auth = getAuth();
      await this.afAuth.sendPasswordResetEmail(email);
    } catch (error) {
      // Handle errors
      console.error(error);
      throw error;
    }
  }

  // loginWithEmail(email: string, password: string, returnUrl: any, cb: any) {
  //   const authType = 'gmail';
  // const auth = getAuth();
  // return signInWithEmailAndPassword(auth, email, password)
  //   .then((userCredential) => {
  //     // Signed in
  //     const user = userCredential.user;

  //     // Check if user exists
  //     this.querySubscription = this.dataService
  //       .getUserDetails(user.uid)
  //       .subscribe(({ data }) => {
  //         localStorage.setItem('userData', JSON.stringify(data.mrcog_users[0]));
  //         this.userData = data.mrcog_users[0];

  //         if (this.userData) {
  //           if (this.userData.selectedTopicId != null) {
  //             if (this.cookieService.check('selectedStream')) {
  //               this.cookieService.delete('selectedStream');
  //             }
  //             if (this.cookieService.check('selectedStreamName')) {
  //               this.cookieService.delete('selectedStreamName');
  //             }
  //             this.cookieService.set(
  //               'selectedStream',
  //               this.userData.selectedTopicId
  //             );
  //             this.cookieService.set(
  //               'selectedStreamName',
  //               this.userData.topic.name
  //             );
  //             if (returnUrl != '/all-courses') {
  //               this.router.navigateByUrl(returnUrl);
  //             } else {
  //               this.router.navigate(['/dashboard']);
  //             }
  //             cb();
  //           } else {
  //             this.router.navigateByUrl(returnUrl);
  //             cb();
  //           }
  //         }
  //       });

  //     return 'Login successful';
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     const errorCode = error.code;
  //     if (errorCode === 'auth/user-not-found') {
  //       // Redirect to register route if user is not found
  //       this.router.navigateByUrl(`/register/${authType}`);
  //     } else {
  //       // Handle other errors
  //       throw error; // Rethrow the error for the calling component to handle
  //     }
  //   })}

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }
}
