// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyA0qlQTi6h90OWf-L8fSy0W_J7RaPJalFs',
    authDomain: 'mrcog-dev.firebaseapp.com',
    databaseURL: 'https://mrcog-dev-default-rtdb.firebaseio.com',
    projectId: 'mrcog-dev',
    storageBucket: 'mrcog-dev.appspot.com',
    messagingSenderId: '580471092613',
    appId: '1:580471092613:web:1b2f323eedb1916bfa00ec',
    measurementId: 'G-GCD8H85444',
  },
  graphql: 'https://mrcoghasura.b-tle.com/v1/graphql',
  cloudfront: {
    url: 'https://dzqqnv5wmbyt9.cloudfront.net',
  },
  bucket: 'mrcog-media-dev',
  nodeRootURL: {
    url: 'https://mrcog-node-git.b-tle.com/',
    // url: 'http://localhost:3000/',
  },
  stripe: {
    PK: 'pk_test_51JdVQQSAK75YdCC529NdcMRlj8YGodixTJHxW6z02rksPC4blerv3AzIWyTXsJHow4MbkrvxFea3AJd2fOMLpxeO00Jw7ANWzu',
    PK2: 'pk_test_51JeIKPSDsHq2yQ4t9JaYYKKjypYTc4SlePvKtnNCwUvnExNYURSOkKomzxq2OGlorhO9HF2WcmbCi6KVqZtJXvqp00GivvvW1X',
  },
  base_URL: 'http://localhost:4200/',
  GTM_ID: 'GTM-K9L4X6R',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 * demo
 *  {
    apiKey: 'AIzaSyBbuFpkD_hASIIT3pvGWpFyQ22GbpUZPiw',
    authDomain: 'mrcog-lms.firebaseapp.com',
    projectId: 'mrcog-lms',
    storageBucket: 'mrcog-lms.appspot.com',
    messagingSenderId: '28386101784',
    appId: '1:28386101784:web:c525d1f75f5ead0052916d',
    measurementId: 'G-W0G8ELM58Z',
  }
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
