import { gql } from 'apollo-angular';
export const Mutations = {
  updateUser: gql`
    mutation UpdateUser(
      $uid: String!
      $email: String!
      $phone: String!
      $name: String!
      $url: String!
    ) {
      update_mrcog_users(
        where: { external_id: { _eq: $uid } }
        _set: {
          platform: "Website"
          email: $email
          name: $name
          phone_number: $phone
          prof_image: $url
        }
      ) {
        affected_rows
      }
    }
  `,
  addQuizResult: gql`
    mutation addQuizResult(
      $uid: String
      $totalMark: float8
      $answerCount: Int
      $numofquestions: Int
      $questionGroupId: uuid
      $ansInfo: String
    ) {
      insert_mrcog_quiz_result(
        objects: {
          correctAnswersCount: $answerCount
          user_id: $uid
          questionGroupTotal: $numofquestions
          questionTotalMark: $totalMark
          ansInfo: $ansInfo
          question_Group_Id: $questionGroupId
        }
      ) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  updateUserTopic: gql`
    mutation UpdateTopic($userId: String, $selectedTopicId: uuid) {
      update_mrcog_users(
        where: { external_id: { _eq: $userId } }
        _set: { selected_topic_id: $selectedTopicId }
      ) {
        affected_rows
      }
    }
  `,
  insertLibraryContent: gql`
    mutation insertLibraryContent(
      $id: uuid!
      $libraryId: String!
      $uid: String!
    ) {
      insert_mrcog_library_content_user_mapping(
        objects: { content_id: $id, library_id: $libraryId, user_id: $uid }
      ) {
        affected_rows
        returning {
          id
          contentId: content_id
          typeId: library_id
          userId: user_id
        }
      }
    }
  `,
  insertEcourseContent: gql`
    mutation insertEcourseContent(
      $id: uuid!
      $ecourseId: uuid!
      $mark: String!
      $uid: String!
    ) {
      insert_mrcog_ecourse_content_user_mapping(
        objects: {
          content_id: $id
          ecourse_id: $ecourseId
          mark: $mark
          user_id: $uid
        }
      ) {
        affected_rows
        returning {
          content_id
          __typename
        }
        __typename
      }
    }
  `,
  insertEcourseNote: gql`
    mutation InsertLibraryNotes(
      $content: String
      $ecourseContentId: uuid
      $userId: String
    ) {
      insert_mrcog_notes(
        objects: {
          user_id: $userId
          content: $content
          ecourse_content_id: $ecourseContentId
        }
      ) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  insertLibraryNote: gql`
    mutation InsertLibraryNotes(
      $content: String
      $libraryContentId: uuid
      $userId: String
    ) {
      insert_mrcog_notes(
        objects: {
          library_content_id: $libraryContentId
          user_id: $userId
          content: $content
        }
      ) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  insertEbookNote: gql`
    mutation InsertLibraryNotes(
      $content: String
      $ebookContentId: uuid
      $userId: String
    ) {
      insert_mrcog_notes(
        objects: {
          ebook_content_id: $ebookContentId
          user_id: $userId
          content: $content
        }
      ) {
        affected_rows
        returning {
          id
        }
      }
    }
  `,
  updateNote: gql`
    mutation UpdateNote($id: uuid, $content: String) {
      update_mrcog_notes(
        where: { id: { _eq: $id } }
        _set: { content: $content }
      ) {
        affected_rows
      }
    }
  `,
  insertEcourseUserMapping: gql`
    mutation insertEcourseContent($ecourseId: uuid!, $uid: String!) {
      insert_mrcog_ecourse_main_user_mapping(
        objects: { ecourse_id: $ecourseId, user_id: $uid, is_enrolled: true }
      ) {
        affected_rows
        returning {
          typeId: ecourse_id
          isEnrolled: is_enrolled
          subscriptionEndingAt: subscription_ending_at
        }
      }
    }
  `,
  updateEcourseUserMapping: gql`
    mutation updateEcourseContent($ecourseId: uuid!, $uid: String!) {
      update_mrcog_ecourse_main_user_mapping(
        where: { ecourse_id: { _eq: $ecourseId }, user_id: { _eq: $uid } }
        _set: { is_enrolled: true }
      ) {
        affected_rows
        returning {
          typeId: ecourse_id
          isEnrolled: is_enrolled
          subscriptionEndingAt: subscription_ending_at
        }
      }
    }
  `,
  updateEcourseRating: gql`
    mutation MyQuery($ecourseId: uuid!, $userId: String!, $rating: Int!) {
      update_mrcog_ecourse_main_user_mapping(
        where: { user_id: { _eq: $userId }, ecourse_id: { _eq: $ecourseId } }
        _set: { rating: $rating, rated_at: "now()" }
      ) {
        affected_rows
        returning {
          rating
        }
      }
    }
  `,
  updateEcourseRating2: gql`
    mutation MyQuery($ecourseId: uuid!, $userId: String!, $rating: Int!) {
      update_mrcog_ecourse_main_user_mapping(
        where: { user_id: { _eq: $userId }, ecourse_id: { _eq: $ecourseId } }
        _set: { rating2: $rating, rated_at: "now()" }
      ) {
        affected_rows
        returning {
          rating
        }
      }
    }
  `,
  insertQuestionFeedback: gql`
    mutation MyQuery(
      $questionId: uuid!
      $userId: String!
      $type: String!
      $feedback: String!
    ) {
      insert_mrcog_questions_feedbacks(
        objects: {
          feedback: $feedback
          question_id: $questionId
          type: $type
          user_id: $userId
        }
      ) {
        affected_rows
        returning {
          id
          feedback
          feedbackTime: feedback_time
          questionId: question_id
          type
          userId: user_id
        }
      }
    }
  `,

  updateQuestionFeedback: gql`
    mutation MyQuery(
      $questionId: uuid!
      $userId: String!
      $type: String!
      $feedback: String!
    ) {
      update_mrcog_questions_feedbacks(
        where: {
          question_id: { _eq: $questionId }
          user_id: { _eq: $userId }
          type: { _eq: $type }
        }
        _set: { feedback: $feedback, feedback_time: "now()" }
      ) {
        affected_rows
        returning {
          id
          feedback
          feedbackTime: feedback_time
          isReplied: is_replied
          questionId: question_id
          reply
          replyTime: reply_time
          type
          userId: user_id
        }
      }
    }
  `,
  insertPrizeData: gql`
    mutation MyMutation(
      $objects: [mrcog_lucky_draw_user_mapping_insert_input!]!
    ) {
      insert_mrcog_lucky_draw_user_mapping(objects: $objects) {
        affected_rows
      }
    }
  `,

  clearNotifications: gql`
    mutation MyMutation($user_id: String) {
      update_mrcog_notifications(
        where: { user_id: { _eq: $user_id } }
        _set: { is_read: true }
      ) {
        affected_rows
      }
    }
  `,

  // user added to chat message
  userAddedMessage: gql`
    mutation userAddedMessage($object: mrcog_chat_messages_insert_input! = {}) {
      insert_mrcog_chat_messages_one(object: $object) {
        id
      }
    }
  `,

  // ebook user mapping
  insertEbookUserMapping: gql`
    mutation insertEbookUserMapping(
      $object: mrcog_ebooks_user_mapping_insert_input! = {}
    ) {
      insert_mrcog_ebooks_user_mapping_one(object: $object) {
        id
      }
    }
  `,

  updateEbookUserMapping: gql`
    mutation updateEbookUserMapping(
      $where: mrcog_ebooks_user_mapping_bool_exp! = {}
      $_set: mrcog_ebooks_user_mapping_set_input! = {}
    ) {
      update_mrcog_ebooks_user_mapping(where: $where, _set: $_set) {
        affected_rows
      }
    }
  `,
};
