import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./pages/faq/faq.module').then((m) => m.FaqModule),
  },

  { path: '', redirectTo: 'home', pathMatch: 'full' },

  {
    path: 'all-courses',
    loadChildren: () =>
      import('./pages/all-courses/all-courses.module').then(
        (m) => m.AllCoursesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'stream/:id',
    loadChildren: () =>
      import('./pages/all-streams/all-streams.module').then(
        (m) => m.AllStreamsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'quiz/:id/:name/:libContentId/:libType',
    loadChildren: () =>
      import('./pages/quiz/quiz.module').then((m) => m.QuizModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'mock_test/:id/:name/:libContentId/:libType',
    loadChildren: () =>
      import('./pages/quiz/quiz.module').then((m) => m.QuizModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'library',
    loadChildren: () =>
      import('./pages/library/library.module').then((m) => m.LibraryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('./pages/pricing/pricing.module').then((m) => m.PricingModule),
  },
  {
    path: 'questions',
    loadChildren: () =>
      import('./pages/questions/questions.module').then(
        (m) => m.QuestionsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./pages/account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quizresult',
    loadChildren: () =>
      import('./pages/quiz-result/quiz-result.module').then(
        (m) => m.QuizResultModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'course',
    loadChildren: () =>
      import('./pages/course-list/course-list.module').then(
        (m) => m.CourseListModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'course/:id',
    loadChildren: () =>
      import('./pages/full-course/full-course.module').then(
        (m) => m.FullCourseModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'podcasts',
    loadChildren: () =>
      import('./pages/podcasts/podcasts.module').then((m) => m.PodcastsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'library/contents',
    loadChildren: () =>
      import('./pages/library-contents/library-contents.module').then(
        (m) => m.LibraryContentsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'productlibrary',
    loadChildren: () =>
      import('./pages/product-library/product-library.module').then(
        (m) => m.ProductLibraryModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'library/:type',
    loadChildren: () =>
      import('./pages/pl-content/pl-content.module').then(
        (m) => m.PlContentModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'library/:type/:id',
    loadChildren: () =>
      import('./pages/pl-items/pl-items.module').then((m) => m.PlItemsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'ecourse',
    loadChildren: () =>
      import('./pages/ecourse/ecourse.module').then((m) => m.EcourseModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'certificate',
    loadChildren: () =>
      import('./pages/certificate/certificate.module').then(
        (m) => m.CertificateModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'ebook',
    loadChildren: () =>
      import('./pages/ebook/ebook.module').then((m) => m.EbookModule),
    canActivate: [AuthGuard],
  },
  // temp hidden {
  //   path: 'lucky-draw',
  //   loadChildren: () =>
  //     import('./pages/lucky-draw/lucky-draw.module').then((m) => m.LuckyDrawModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'media/:type/:id/:content',
    loadChildren: () =>
      import('./pages/media-content/media-content.module').then(
        (m) => m.MediaContentModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'ecourse/:id',
    loadChildren: () =>
      import('./pages/ec-content/ec-content.module').then(
        (m) => m.EcContentModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'payment/:type/:id',
    loadChildren: () =>
      import('./pages/payment/payment.module').then((m) => m.PaymentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'checkout/:path',
    loadChildren: () =>
      import('./pages/checkout/checkout.module').then((m) => m.CheckoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'questionbank',
    loadChildren: () =>
      import('./pages/question-bank/question-bank.module').then(
        (m) => m.QuestionBankModule
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
// export const routingComponents = [PricingComponent, HomeComponent];
