import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FirebaseService } from './services/firebase.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';

import { JwtInterceptor } from './interceptor/token.interceptor';
import { DataService } from './services/data.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UploadService } from './services/upload.service';
import { ImageCompressService } from './services/image-compress.service';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    HttpClientModule,
    NgxIntlTelInputModule,
    NgxStripeModule.forRoot(environment.stripe.PK),
    NgChartsModule,
    ApolloModule,
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphql,
          }),
        };
      },
      deps: [HttpLink],
    },
    FirebaseService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    DataService,
    UploadService,
    ImageCompressService,
    NgxImageCompressService,
    { provide: 'googleTagManagerId', useValue: 'GTM-K9L4X6R' },
    { provide: 'googleTagManagerId', useValue: 'GTM-PFVL47K' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
