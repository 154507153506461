import { Injectable } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';
@Injectable({
  providedIn: 'root',
})
export class ImageCompressService {
  localCompressedURl: any;
  sizeOfOriginalImage: number = 0;
  sizeOFCompressedImage: number = 0;
  private imageFile: any;
  constructor(private imageCompress: NgxImageCompressService) {}
  imgResultBeforeCompress: string = '';
  imgResultAfterCompress: string = '';

  compressFile(image: any, fileName: string, cb: any) {
    var orientation = -1;
    this.sizeOfOriginalImage =
      this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', this.sizeOfOriginalImage);

    this.imageCompress
      .compressFile(image, orientation, 50, 50)
      .then((result:any) => {
        this.imgResultAfterCompress = result;
        this.localCompressedURl = result;
        this.sizeOFCompressedImage =
          this.imageCompress.byteCount(result) / (1024 * 1024);
        console.warn(
          'Size in bytes after compression:',
          this.sizeOFCompressedImage
        );
        // create file from byte
        const imageName = fileName;
        // call method that creates a blob from dataUri
        const imageBlob = this.dataURItoBlob(
          this.imgResultAfterCompress.split(',')[1]
        );
        this.imageFile = new File([imageBlob], imageName, {
          type: 'image/jpeg',
        });
        cb(this.imageFile, this.imgResultAfterCompress);
        // this.uploadService.uploadFile(this.imageFile, this.user.uid);
        console.log('file size:', this.imageFile['size'] / (1024 * 1024));
      });
  }
  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }
}
