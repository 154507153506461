import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'mrcog-angular';
  constructor() {
    // this.generateFakeRightClick();
  }

  generateFakeRightClick() {
    const isCopyEnabled = document.queryCommandEnabled('copy');
    console.log(`Is copy enabled? ${isCopyEnabled}`);

    document.addEventListener('contextmenu', (event) => {
      event.preventDefault(); // prevent the default context menu from appearing
      console.log('Right-clicked!');
    });
    // const event = new MouseEvent('contextmenu', {
    //   bubbles: true,
    //   cancelable: true,
    //   button: 2, // 2 is the code for the right mouse button
    // });
    // document.dispatchEvent(event);
  }
}
