import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  FOLDER: string = 'Users/Profile/';
  constructor(private sharedService: SharedService) {}
  uploadFile(file: any, uid: string, cb: any) {
    const contentType = file.type;
    const fileExt = file.name.split('.').pop();
    const finalname = uuidv4() + '.' + fileExt;
    const bucket = new S3({
      accessKeyId: this.sharedService.configurations?.aws_web_access_key,
      secretAccessKey: this.sharedService.configurations.aws_web_secret_key,
      region: this.sharedService.configurations.aws_web_region,
    });
    const params = {
      Bucket: this.sharedService.configurations.aws_web_media_bucket,
      Key: this.FOLDER + uid + '/' + finalname,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };
    bucket.upload(params, function (err: any, data: any) {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }
      // console.log('Successfully uploaded file.', data);
      cb(data);
      return true;
    });
    //for upload progress
    /*bucket.upload(params).on('httpUploadProgress', function (evt) {
          console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
      }).send(function (err, data) {
          if (err) {
              console.log('There was an error uploading your file: ', err);
              return false;
          }
          console.log('Successfully uploaded file.', data);
          return true;
      });*/
  }
}
