import { Injectable } from '@angular/core';
import { Apollo, Mutation } from 'apollo-angular';
import { Queries } from '../shared/APIs/queries.graphql';
import { Mutations } from '../shared/APIs/mutations.graphql';
import { LibraryCourse } from '../data-model/library-course';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LibraryCategory } from '../data-model/library-category';
import { ECourseCategory } from '../data-model/ecourse-category';
import { ECourse } from '../data-model/ecourse';
import { PaymentRequest } from '../data-model/payment-request';
import { Subscription } from '../data-model/subscription';
import { EcourseMainUserMappings } from '../data-model/ecourse-main-user-mappings';
import { PriceDetails } from '../data-model/price-details';
import { UserContent } from '../data-model/user-content';
import { QuestionFeedback } from '../data-model/question-feedback';
import { Library } from '../data-model/library';
import { Course } from '../data-model/course';
import { PaymentGateway } from '../data-model/payment-gateway';
import { Notifications } from '../data-model/notifications';
import { responsePathAsArray } from 'graphql';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  
  constructor(private apollo: Apollo) {}

  public getAccountUsageData(user_uid: string) {
    return this.apollo.query<any>({
      query: Queries.getUserAccountData,
      variables: {
        user_id: user_uid,
      },
    });
  }
  public getCourses() {
    return this.apollo.query<any>({
      query: Queries.listCourses,
    });
  }
  public getStreams(uid: string) {
    return this.apollo.query<any>({
      query: Queries.listStreams,
      variables: {
        uid: uid,
      },
    });
  }
  public getUserData(user_uid: string) {
    return this.apollo.query<any>({
      query: Queries.getUserData,
      variables: {
        uid: user_uid,
      },
    });
  }
  public getDashboardContents(id: string) {
    return this.apollo.query<any>({
      query: Queries.getDashboardContents,
      variables: {
        id,
      },
    });
  }

  public updateUserDetails(
    uid: string,
    email: string,
    phone: string,
    name: string,
    url: string
  ) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateUser,
      variables: {
        uid: uid,
        email: email,
        phone: phone,
        name: name,
        url: url,
      },
    });
  }
  public addQuizResult(
    uid: string,
    totalMark: number,
    answerCount: number,
    numofquestions: number,
    questionGroupId: string,
    ansInfo: string
  ) {
    return this.apollo.mutate<any>({
      mutation: Mutations.addQuizResult,
      variables: {
        uid: uid,
        totalMark: totalMark,
        answerCount: answerCount,
        numofquestions: numofquestions,
        questionGroupId: questionGroupId,
        ansInfo: ansInfo,
      },
    });
  }
  public listProductLibrariesByType(
    type: string,
    offset: number,
    limit: number,
    streamId: string,
    userId: string
  ) {
    return this.apollo.query<any>({
      query: Queries.listProductLibrariesByType,
      variables: {
        type,
        offset,
        limit,
        streamId,
        userId,
      },
    });
  }
  public getECourse(id: string, userid: string): Observable<ECourse> {
    return this.apollo
      .query<any>({
        query: Queries.getECourse,
        variables: {
          id,
          userid,
        },
      })
      .pipe(
        map((response) => {
          return <ECourse>response.data.mrcog_ecourse_main[0];
        })
      );
  }
  public getEbook(topic: string) {
    return this.apollo.query<any>({
      query: Queries.GetEbook,
      variables: {
        topic,
      },
    });
  }

  public getEbookUserMappings(variables: any) {
    return this.apollo.query<any>({
      query: Queries.getEbookUserMappings,
      variables,
    });
  }

  public getEcourseUsageByWeek(
    firstDayOfWeek: string,
    lastDayOfWeek: string,
    userId: string
  ) {
    return this.apollo.query<any>({
      query: Queries.ecourseUsageByWeek,
      variables: {
        firstDayOfWeek,
        lastDayOfWeek,
        userId,
      },
    });
  }
  public getMonthlyUsageData(
    firstDayOfYear: string,
    lastDayOfYear: string,
    userId: string
  ) {
    return this.apollo.query<any>({
      query: Queries.monthlyContentView,
      variables: {
        firstDayOfYear,
        lastDayOfYear,
        userId,
      },
    });
  }
  public listLibraryCategoriesById(
    libraryId: string
  ): Observable<LibraryCategory[]> {
    return this.apollo
      .query<any>({
        query: Queries.listLibraryCategoriesById,
        variables: {
          id: libraryId,
        },
      })
      .pipe(
        map((response) => {
          return <LibraryCategory[]>response.data.mrcog_library_categories;
        })
      );
  }

  public getSubscriptionLibrarySubscription(
    id: string,
    userId: string
  ): Observable<EcourseMainUserMappings> {
    return this.apollo
      .query<any>({
        query: Queries.getSubscriptionLibrarySubscription,
        variables: {
          id,
          userId,
        },
      })
      .pipe(
        map((response) => {
          return <EcourseMainUserMappings>(
            response.data.mrcog_library_user_mapping[0]
          );
        })
      );
  }

  public getLibraryContentById(
    id: string,
    userid: string
  ): Observable<LibraryCategory> {
    return this.apollo
      .watchQuery<any>({
        query: Queries.getLibraryContentById,
        variables: {
          id,
          userid,
        },
      })
      .valueChanges.pipe(
        map((response) => {
          return <LibraryCategory>response.data.mrcog_library_categories[0];
        })
      );
  }
  public getUser(id: string) {
    return this.apollo.query<any>({
      query: Queries.getUser,
      variables: {
        id,
      },
    });
  }
  public getLibraryUsageById(libraryId: string, userId: string) {
    return this.apollo.query<any>({
      query: Queries.getLibraryProgress,
      variables: {
        libraryId,
        libId: libraryId,
        userId,
      },
    });
  }
  public getTrendingVideos() {
    return this.apollo.query<any>({
      query: Queries.getTrendingVideos,
    });
  }
  public listECourseCategories(eid: string) {
    return this.apollo.query<any>({
      query: Queries.listECourseCategories,
      variables: {
        eid: eid,
      },
    });
  }
  public listECourseCategoriesById(cid: string): Observable<ECourseCategory> {
    return this.apollo
      .query<any>({
        query: Queries.listECourseCategoriesById,
        variables: {
          cid: cid,
        },
      })
      .pipe(
        map((response) => {
          return <ECourseCategory>response.data.mrcog_ecourse_categories[0];
        })
      );
  }
  public getMediaContentById(id: string) {
    return this.apollo.query<any>({
      query: Queries.getMediaContentById,
      variables: {
        id,
      },
    });
  }
  public updateUserTopic(userId: string, selectedTopicId: string) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateUserTopic,
      variables: {
        userId,
        selectedTopicId,
      },
    });
  }
  public getUserDetails(user_uid: string) {
    return this.apollo.query<any>({
      query: Queries.getUserDetails,
      fetchPolicy: 'network-only',
      variables: {
        uid: user_uid,
      },
    });
  }
  public getEcourseViewCounts(userId: string) {
    return this.apollo.query<any>({
      query: Queries.getEcourseViewCounts,
      fetchPolicy: 'network-only',
      variables: {
        userId,
      },
    });
  }
  public getLibraryViewCounts(userId: string) {
    return this.apollo.query<any>({
      query: Queries.getLibraryViewCounts,
      fetchPolicy: 'network-only',
      variables: {
        userId,
      },
    });
  }
  public getWebConfigurations() {
    return this.apollo.query<any>({
      query: Queries.getWebConfigurations,
    });
  }
  public getImageSetData(id: string) {
    return this.apollo.query<any>({
      query: Queries.getImageSetData,
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });
  }
  public getLibraryContent(id: string) {
    return this.apollo.query<any>({
      query: Queries.getLibraryContent,
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });
  }
  public CheckEcoursePurchase(userId: string, contentId: string) {
    return this.apollo.query<any>({
      query: Queries.CheckEcoursePurchase,
      fetchPolicy: 'network-only',
      variables: {
        userId,
        contentId,
      },
    });
  }
  public checkPurchase(userId: string, contentId: string) {
    return this.apollo.query<any>({
      query: Queries.checkPurchase,
      fetchPolicy: 'network-only',
      variables: {
        userId,
        contentId,
      },
    });
  }
  public getEcourseContent(id: string) {
    return this.apollo.query<any>({
      query: Queries.getEcourseContent,
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });
  }
  public getQuizData(id: string) {
    return this.apollo.query<any>({
      query: Queries.getQuizContent,
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });
  }
  public saveEcourseContentUserMapping(
    id: string,
    ecourseId: string,
    mark: string,
    uid: string
  ) {
    return this.apollo.mutate<any>({
      mutation: Mutations.insertEcourseContent,
      variables: {
        id,
        ecourseId,
        mark,
        uid,
      },
    });
  }
  public saveLibraryContentUserMapping(
    id: string,
    libraryId: string,
    uid: string
  ): Observable<UserContent[]> {
    return this.apollo
      .mutate<any>({
        mutation: Mutations.insertLibraryContent,
        variables: {
          id,
          libraryId,
          uid,
        },
        refetchQueries: [Queries.getLibraryContentById],
      })
      .pipe(
        map((response) => {
          return <UserContent[]>(
            response.data.insert_mrcog_library_content_user_mapping.returning
          );
        })
      );
  }
  public getFlipCardContents(id: string) {
    return this.apollo.query<any>({
      query: Queries.getFlipCardContent,
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });
  }

  public listECourseSubscription(id: string): Observable<Subscription[]> {
    return this.apollo
      .query<any>({
        query: Queries.listECourseSubscription,
        variables: {
          id,
        },
      })
      .pipe(
        map((response) => {
          return <Subscription[]>response.data.mrcog_ecourse_main_plan_mapping;
        })
      );
  }
  public getEcourseNotes(userId: String, libraryId: string) {
    return this.apollo.query<any>({
      query: Queries.getEcourseNotes,
      fetchPolicy: 'network-only',
      variables: {
        userId,
        libraryId,
      },
    });
  }
  public getLibraryNotes(userId: String, libraryId: string) {
    return this.apollo.query<any>({
      query: Queries.getLibraryNotes,
      fetchPolicy: 'network-only',
      variables: {
        userId,
        libraryId,
      },
    });
  }
  public getEbookNotes(userId: String, ebookId: string) {
    return this.apollo.query<any>({
      query: Queries.getEbookNotes,
      fetchPolicy: 'network-only',
      variables: {
        ebookId,
        userId,
      },
    });
  }
  public getLuckyDrawDetails(user_id: String) {
    return this.apollo.query<any>({
      query: Queries.getLuckyDrawDetails,
      fetchPolicy: 'network-only',
      variables: {
        user_id,
      },
    });
  }
  public getLuckyDraw() {
    return this.apollo.query<any>({
      query: Queries.getLuckyDraw,
      fetchPolicy: 'network-only',
    });
  }
  public InsertEcourseNote(
    content: String,
    ecourseContentId: string,
    userId: String
  ) {
    return this.apollo.mutate<any>({
      mutation: Mutations.insertEcourseNote,
      variables: {
        content,
        ecourseContentId,
        userId,
      },
    });
  }
  public InsertPrizeData(objects: {}) {
    return this.apollo.mutate<any>({
      mutation: Mutations.insertPrizeData,
      variables: {
        objects,
      },
    });
  }
  public InsertLibraryNote(
    content: String,
    libraryContentId: string,
    userId: String
  ) {
    return this.apollo.mutate<any>({
      mutation: Mutations.insertLibraryNote,
      variables: {
        content,
        libraryContentId,
        userId,
      },
    });
  }
  public InsertEbookNote(
    content: String,
    ebookContentId: string,
    userId: String
  ) {
    return this.apollo.mutate<any>({
      mutation: Mutations.insertEbookNote,
      variables: {
        content,
        ebookContentId,
        userId,
      },
    });
  }
  public updateNote(id: string, content: String) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateNote,
      variables: {
        id,
        content,
      },
    });
  }

  public getCourse(id: string, userId: string) {
    return this.apollo.query<any>({
      query: Queries.getCourse,
      variables: {
        id,
        userId,
      },
      fetchPolicy: 'network-only',
    });
  }

  public getCategoryPrice(id: string): Observable<PriceDetails> {
    return this.apollo
      .query<any>({
        query: Queries.getCategoryPrice,
        variables: {
          id,
        },
      })
      .pipe(
        map((response) => {
          return <PriceDetails>response.data.mrcog_library_categories[0];
        })
      );
  }

  public listLibrarySubscription(
    id: string,
    type: string
  ): Observable<Subscription[]> {
    return this.apollo
      .query<any>({
        query: Queries.listLibrarySubscription,
        variables: {
          id,
          type,
        },
      })
      .pipe(
        map((response) => {
          return <Subscription[]>response.data.mrcog_library_plan_mapping;
        })
      );
  }

  public insertEcourseUserMapping(
    ecourseId: string,
    uid: string
  ): Observable<EcourseMainUserMappings> {
    return this.apollo
      .mutate<any>({
        mutation: Mutations.insertEcourseUserMapping,
        variables: {
          ecourseId,
          uid,
        },
      })
      .pipe(
        map((response) => {
          return <EcourseMainUserMappings>(
            response.data.insert_mrcog_ecourse_main_user_mapping.returning[0]
          );
        })
      );
  }

  public updateEcourseUserMapping(
    ecourseId: string,
    uid: string
  ): Observable<EcourseMainUserMappings> {
    return this.apollo
      .mutate<any>({
        mutation: Mutations.updateEcourseUserMapping,
        variables: {
          ecourseId,
          uid,
        },
      })
      .pipe(
        map((response) => {
          return <EcourseMainUserMappings>(
            response.data.update_mrcog_ecourse_main_user_mapping.returning[0]
          );
        })
      );
  }

  public updateEcourseRating(
    ecourseId: string,
    userId: string,
    rating: number
  ): Observable<number> {
    return this.apollo
      .mutate<any>({
        mutation: Mutations.updateEcourseRating,
        variables: {
          ecourseId,
          userId,
          rating,
        },
        refetchQueries: [Queries.getECourse, Queries.getECourseRating],
      })
      .pipe(
        map((response) => {
          return <number>(
            response.data.update_mrcog_ecourse_main_user_mapping.returning[0]
              .rating
          );
        })
      );
  }
  public updateEcourseRating2(
    ecourseId: string,
    userId: string,
    rating: number
  ): Observable<number> {
    return this.apollo
      .mutate<any>({
        mutation: Mutations.updateEcourseRating2,
        variables: {
          ecourseId,
          userId,
          rating,
        },
        refetchQueries: [Queries.getECourse, Queries.getECourseRating],
      })
      .pipe(
        map((response) => {
          return <number>(
            response.data.update_mrcog_ecourse_main_user_mapping.returning[0]
              .rating
          );
        })
      );
  }

  public getECourseRating(
    ecourseId: string,
    userId: string
  ): Observable<number> {
    return this.apollo
      .query<any>({
        query: Queries.getECourseRating,
        variables: {
          ecourseId,
          userId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response) => {
          return response.data.mrcog_ecourse_main_user_mapping[0];
        })
      );
  }

  public listCourseSubscription(id: string): Observable<Subscription[]> {
    return this.apollo
      .query<any>({
        query: Queries.listCourseSubscription,
        variables: {
          id,
        },
      })
      .pipe(
        map((response) => {
          return <Subscription[]>response.data.mrcog_course_plan_mapping;
        })
      );
  }

  public getQuestionFeedback(
    questionId: string,
    userId: string,
    type: string
  ): Observable<QuestionFeedback> {
    return this.apollo
      .query<any>({
        query: Queries.getQuestionFeedback,
        variables: {
          questionId,
          userId,
          type,
        },
      })
      .pipe(
        map((response) => {
          return <QuestionFeedback>response.data.mrcog_questions_feedbacks[0];
        })
      );
  }

  public insertQuestionFeedback(feedback: any) {
    return this.apollo
      .mutate<any>({
        mutation: Mutations.insertQuestionFeedback,
        variables: {
          questionId: feedback.questionId,
          userId: feedback.userId,
          type: feedback.type,
          feedback: feedback.feedback,
        },
      })
      .pipe(
        map((response) => {
          return <QuestionFeedback>(
            response.data.insert_mrcog_questions_feedbacks.returning[0]
          );
        })
      );
  }

  public updateQuestionFeedback(feedback: any) {
    return this.apollo
      .mutate<any>({
        mutation: Mutations.updateQuestionFeedback,
        variables: {
          questionId: feedback.questionId,
          userId: feedback.userId,
          type: feedback.type,
          feedback: feedback.feedback,
        },
      })
      .pipe(
        map((response) => {
          return <QuestionFeedback>(
            response.data.update_mrcog_questions_feedbacks.returning[0]
          );
        })
      );
  }

  public getECourseUserRating(
    ecourseId: string,
    userId: string
  ): Observable<number> {
    return this.apollo
      .query<any>({
        query: Queries.getECourseUserRating,
        variables: {
          ecourseId,
          userId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response) => {
          return response.data.mrcog_ecourse_main_user_mapping[0];
        })
      );
  }

  // rate now button only 2 times

  // public getECourseUserRating(ecourseId: string, userId: string) {
  //   return this.apollo
  //     .query<any>({
  //       query: Queries.getECourseUserRating,
  //       variables: {
  //         ecourseId,
  //         userId,
  //       },
  //       fetchPolicy: 'no-cache',
  //     })

  //     .pipe(
  //       map((response) => {
  //         return response.data.mrcog_ecourse_main_user_mapping[0];
  //       })
  //     );
  // }

  public getCourseLibraryUserMapping(userId: string, libraryId: string) {
    return this.apollo.query<any>({
      query: Queries.getCourseLibraryUserMapping,
      variables: {
        userId,
        libraryId,
      },
      fetchPolicy: 'network-only',
    });
  }

  public listAllCoursesByTopic(streamId: string): Observable<Course[]> {
    return this.apollo
      .query<any>({
        query: Queries.listAllCoursesByTopic,
        variables: {
          streamId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          return <Course[]>response.data.mrcog_course;
        })
      );
  }

  // ebook price

  public getEbookPrice(ebookId: string): Observable<PriceDetails> {
    return this.apollo
      .query<any>({
        query: Queries.getEbookPrice,
        variables: {
          ebookId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          return <PriceDetails>response.data.mrcog_ebooks[0];
        })
      );
  }

  public getCoursePrice(courseId: string): Observable<PriceDetails> {
    return this.apollo
      .query<any>({
        query: Queries.getCoursePrice,
        variables: {
          courseId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          return <PriceDetails>response.data.mrcog_course[0];
        })
      );
  }
  public getFlaggedQuestions(userId: String) {
    return this.apollo.query<any>({
      query: Queries.GetFlaggedQuestions,
      variables: {
        userId,
      },
    });
  }
  public getPurchases(userId: String) {
    return this.apollo.query<any>({
      query: Queries.GetPurchases,
      variables: {
        userId,
      },
    });
  }
  public getPurchasedCourses(userId: String) {
    return this.apollo.query<any>({
      query: Queries.GetPurchasedCourses,
      variables: {
        userId,
      },
    });
  }

  public listPaymentGateways(): Observable<PaymentGateway[]> {
    return this.apollo
      .query<any>({
        query: Queries.listPaymentGateways,
      })
      .pipe(
        map((response) => {
          return <PaymentGateway[]>response.data.mrcog_payment_gateways;
        })
      );
  }

  public getNotifications(user_id: String) {
    return this.apollo
      .query<any>({
        query: Queries.getNotifications,
        variables: {
          user_id,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          return <Notifications>response.data.mrcog_notifications;
        })
      );
  }

  public clearNotifications(user_id: String) {
    return this.apollo.mutate<any>({
      mutation: Mutations.clearNotifications,
      variables: {
        user_id,
      },
      refetchQueries: [
        {
          query: Queries.getNotifications,
          variables: {
            user_id: user_id,
          },
        },
      ],
    });
  }

  // get chat room id from course id
  public getChatRoomId(course_id: string) {
    return this.apollo.query<any>({
      query: Queries.getChatRooomId,
      variables: {
        course_id,
      },
      fetchPolicy: 'network-only',
    });
  }

  // add user added message to chat room

  public userAddedMessage(variables: any): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: Mutations.userAddedMessage,
      variables,
    });
  }

  // get device token

  public getDeviceToken(user_id: string) {
    return this.apollo.query<any>({
      query: Queries.getDeviceToken,
      variables: {
        user_id,
      },
    });
  }

  // inset ebook user mapping
  public insertEbookUserMapping(variables: any) {
    return this.apollo.mutate<any>({
      mutation: Mutations.insertEbookUserMapping,
      variables,
    });
  }

  // update ebook user mapping
  public updateEbookUserMapping(variables: any) {
    return this.apollo.mutate<any>({
      mutation: Mutations.updateEbookUserMapping,
      variables,
    });
  }

  public checkUserEmailExist(email: string) {
    return this.apollo.query<any>({
      query: Queries.checkUserEmailExist,
      variables: {
        email: email,
      },
    });
  }

  
}
